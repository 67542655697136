import {
    useStore
} from "vuex";
import {
    ref
} from "vue";


export function useApi(cb = () => {}) {
    const store = useStore();

    const getterName = cb.name;
    const hasGetterName = `has${cb.name}`;
    const setterName = `set${cb.name}`;

    const {
        data,
        error,
        isLoading,
        run
    } = useAsync(async () => {
        if (store.getters[hasGetterName]) {
            return store.getters[getterName];
        }

        const data = cb();

        store.commit(setterName, data);
        return data;
    });

    return {
        data,
        error,
        isLoading,
        run
    };
}

function useAsync(cb = () => {}) {
    const data = ref(null);
    const error = ref(null);
    const isLoading = ref(false);
    const run = async () => {
        try {
            isLoading.value = true;
            data.value = await cb();
        } catch (e) {
            error.value = processError(e);
        } finally {
            isLoading.value = false;
        }
    };

    return {
        data,
        error,
        isLoading,
        run
    };
}

function processError(error) {
    // TODO
    return error;
}