<template>
  <div>
    <div>
      <button @click="getUsers">Get Users</button>
    </div>
    <div>
      <div v-if="isLoading">Loading ...</div>

      <div v-else-if="error">
        {{ error }} <button @click="getUsers">try again</button>
      </div>
      <div v-else>{{ data }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { FetchData } from "@/data/users.js";

export default defineComponent({
  setup() {
    const { data, error, isLoading, run: getUsers } = FetchData();
    onMounted(async () => {
      await getUsers();
    });
    return { data, error, isLoading, getUsers };
  },
});
</script>

<style></style>
