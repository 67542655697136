import axios from 'axios';
import {
    useApi
} from './myapi';

export function FetchData() {
    return useApi(Users)
}
const Users = async () => {
    const response = await axios("https://solo.com.sa/api/product/list");
    return normalizeUsersReponse(response);
};

export function normalizeUsersReponse(response) {
    return response.data.map(normalizeUser);
}

export function normalizeUser(user) {
    return {
        id: user.id,
        name: user.name,
        description: user.description,
        image: user.image,
        active: user.active,
        // image: user.images.main || "/assets/placeholder.png",
    };
}

export function serializeUser(user) {
    return {
        id: user.id,
        name: user.name,
        description: user.description,
        active: user.active,
    };
}